import React from "react";

import {
  Row,
  Col,
  Button,
  Card,
  Tag,
  Typography,
  Layout,
  Menu,
  Anchor,
} from "antd";
import { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";

import axios from "axios";
import remarkGfm from "remark-gfm";
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
  useNavigate,
} from "react-router-dom";

import createPersistedState from "use-persisted-state";
import Auth from "../services/auth";

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const CustomHeader = () => {
  const auth = new Auth();
  const navigate = useNavigate();
  const location = useLocation();
  //   console.log("location", location);
  const logout = () => {
    auth.logout();
    navigate("/");
    window.location.reload();
  };
  return (
    <Header className="header" style={{ background: "#ffffff00" }}>
      <Row style={{ maxWidth: "1400px", margin: "0px auto" }}>
        <Col span={4}>
          {/* <div className="logo">RebootRx</div>
           */}
          <img
            src="/reboot-logo.png"
            style={{ height: "50px", marginTop: "0.5rem" }}
          />
        </Col>
        <Col span={20}>
          <div style={{ float: "right" }}>
            <Button
              onClick={() => navigate("/dataset")}
              type={location.pathname.match(/\/dataset/) ? "link" : "text"}
            >
              Clinical Data
            </Button>

            <Button
              onClick={() => navigate("/bookmarks")}
              type={location.pathname.match(/\/bookmarks/) ? "link" : "text"}
            >
              Bookmarks
            </Button>
            <Button
              onClick={() => navigate("/pubmed-search")}
              type={
                location.pathname.match(/\/pubmed-search/) ? "link" : "text"
              }
            >
              PubMed Search
            </Button>

            <Button
              onClick={() => navigate("/validation")}
              type={location.pathname.match(/\/validation/) ? "link" : "text"}
            >
              Article Validation
            </Button>

            <Button
              onClick={() => navigate("/label")}
              // Set it based on regex
              //   type={location.pathname == "/label" ? "link" : "text"}
              type={location.pathname.match(/\/label/) ? "link" : "text"}
            >
              Data Labeling
            </Button>
            <Button
              onClick={() => navigate("/gpt-summary")}
              type={location.pathname == "/gpt-summary" ? "link" : "text"}
            >
              GPT Summarization
            </Button>
            <Button onClick={logout} type="text">
              Logout
            </Button>
          </div>
        </Col>
      </Row>
      <div className="logo" />
      {/* <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']} items={items1} /> */}
    </Header>
  );
};

export default CustomHeader;

import React, { useState } from "react";
import CustomHeader from "../../components/header";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { ValidationTask } from "../../services/dataService";
import createPersistedState from "use-persisted-state";
import {
  List,
  Tag,
  Typography,
  Button,
  Form,
  Input,
  Radio,
  Space,
  Modal,
  Row,
  Col,
  Select,
  message,
} from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { useAuth } from "../../authContext";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useMatch } from "react-router-dom";
const validationAppState = createPersistedState("validationAppState");

const ResponseForm = ({ id, article, getNext }) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const valTask = useQuery(["validation_task", id], () =>
    ValidationTask.get(id).then((res) => res)
  );

  const submitMutation = useMutation((data) => {
    return ValidationTask.saveAnnotation(id, data);
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(["validation_tasks"]);
    }, 
    onError: (error) => {
      message.error("Error saving validation");
    }
  
  });

  const deleteAnnotation = useMutation((id) => {
    return ValidationTask.deleteAnnotation(id);
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(["validation_tasks"]);
    }
  });

  const handleSubmit = async (values) => {
    await submitMutation.mutateAsync({
      ...values,
      pubmed_val_meta_id: id,
      validator: user.username,
      pubmed_id: article.pubmed_id,
      drug: article.drug,
    });
    queryClient.invalidateQueries(["validation_tasks"]);

    message.success("Validation saved successfully");
  };

  const handleSubmitWithNext = async (values) => {
    let res = await submitMutation.mutateAsync({
      ...values,
      pubmed_val_meta_id: id,
      validator: user.username,

      pubmed_id: article.pubmed_id,
      drug: article.drug,
    });
    queryClient.invalidateQueries(["validation_tasks"]);
    message.success("Validation saved successfully");
    getNext();
  };
  const [form] = Form.useForm();

  const annotation = valTask.data?.annotation
    ? valTask.data.annotation
    : null;

  const studyTypes = [
    "Preclinical",
    "Case Study",
    "Clinical trial: non-RCT",
    "Clinical trial: RCT",
    "Observational study",
    "Meta-analysis",
    "Review",
  ];

  let gptStudyType =
    article.gpt_output?.study_type?.toLowerCase() == "preclinical"
      ? "Preclinical"
      : article.gpt_output?.rct?.toLowerCase();

  // Check if any of the study types are present in the article
  if (gptStudyType) {
    let studyType = studyTypes.find((type) =>
      gptStudyType.includes(type.toLowerCase())
    );
    if (studyType) {
      article.gpt_output.study_type = studyType;
    }
  }

  const initialValues = annotation
    ? annotation
    : {
        n_patients: article.gpt_output?.patient_num || "N/A",
        // queried_drug: article.gpt_output?.drugs?.join(", ") || article.drug,
        published_date: article.other_details?.publication_date || "N/A",
        relevance: article.gpt_output?.relevance || "include",
        study_type: article.gpt_output?.study_type || "",
        // therapeutic_asoociation: article.gpt_output?.ta || "",
        // combination: article.gpt_output?.combination || "",
        tissue_type: article.pipeline_outputs?.primary_tissue_final || "",
      };

  console.log("initialValues", initialValues);

  return (
    <div style={{ margin: "2rem 0rem" }}>
      <Row gutter={24}>
        <Col
          span={12}
          style={{
            height: "80vh",
            overflow: "auto",
          }}
        >
          <div>
            <Typography.Paragraph>
              <span style={{ fontWeight: 500 }}>Title: </span> {article.title}
            </Typography.Paragraph>
          </div>
          <div>
            <Typography.Paragraph>
              <span style={{ fontWeight: 500 }}>Abstract: </span>{" "}
              {article.abstract}
            </Typography.Paragraph>
          </div>
        </Col>
        <Col
          span={12}
          style={{
            height: "80vh",
            overflow: "auto",
          }}
        >
          {valTask.data && (
            <Form
              layout="vertical"
              form={form}
              name="register"
              onFinish={handleSubmit}
              // initialValues={props.existingLabels}
              initialValues={initialValues}
              style={{ maxWidth: 600 }}
              scrollToFirstError
            >
              {/* <Form.Item
                name="queried_drug"
                label="Queried Drug Name"
                rules={[
                  { required: true, message: "Please add  queried drug name!" },
                ]}
              >
                <Input />
              </Form.Item> */}
              <Form.Item
                name="published_date"
                label="Published Date"
                // rules={[{ required: true, message: "Published Date!" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="relevance"
                label="Relevance"
                rules={[
                  { required: true, message: "Please add relevance label!" },
                ]}
              >
                <Radio.Group optionType="button">
                  <Radio value="Include">Include</Radio>
                  <Radio value="Exclude">Exclude</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name="study_type"
                label="Study Type"
                rules={[{ required: true, message: "Please add Study Type!" }]}
              >
                <Radio.Group optionType="button">
                  <Radio value="Preclinical">Preclinical</Radio>
                  <Radio value="Case Study">Case Study</Radio>
                  <Radio value="Clinical trial: non-RCT">
                    Clinical trial: non-RCT
                  </Radio>
                  <Radio value="Clinical trial: RCT">Clinical trial: RCT</Radio>
                  <Radio value="Observational study">Observational study</Radio>
                  <Radio value="Meta-analysis">Meta-analysis</Radio>
                  <Radio value="Review">Review</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name="therapeutic_asoociation"
                label="Therapeutic Association"
                rules={[
                  {
                    required: false,
                    message: "Please add Therapeutic Association!",
                  },
                ]}
              >
                <Radio.Group optionType="button">
                  <Radio value="No benefit">No benefit</Radio>
                  <Radio value="Possible benefit">Possible benefit</Radio>
                  <Radio value="Inconclusive">Inconclusive</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name="combination"
                label="Primary drug combination status"
                rules={[{ required: false, message: "Please choose!" }]}
              >
                <Radio.Group optionType="button">
                  <Radio value="alone">Alone</Radio>
                  <Radio value="combination">Combination</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name="queried_drug_randomized"
                label="Is Queried Drug Primary Difference between arms"
              >
                <Radio.Group optionType="button">
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name="n_patients"
                label="Num Patients, if Clinical (else write 'N/A')"
              >
                <Input />
              </Form.Item>

              <Form.Item name="tissue_type" label="Tissue Type">
                <Input />
              </Form.Item>

              <Form.Item name="tissue_stage" label="Tissue Stage">
                <Radio.Group optionType="button">
                  <Radio value="Early">Early</Radio>
                  <Radio value="Advanced">Advanced</Radio>
                  <Radio value="N/A">N/A</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item name="study_population" label="Study population">
                <Input />
              </Form.Item>

              <Form.Item name="outcome" label="Outcome">
                <Radio.Group optionType="button">
                  <Radio value="Survival">Survival</Radio>
                  <Radio value="Surrogate">Surrogate</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name="serious_adverse_event"
                label="Serious Adverse Events?"
              >
                <Radio.Group optionType="button">
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item name="overall_opinion" label="Overall Opinion">
                <Radio.Group optionType="button">
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item name="reason_for_opinion" label="Reason for Opinion">
                <Input.TextArea />
              </Form.Item>

              <Form.Item>
                <Button htmlType="submit" loading={submitMutation.isLoading}>
                  Submit
                </Button>
                <Button
                  type="primary"
                  loading={submitMutation.isLoading}
                  style={{
                    marginLeft: "1rem",
                  }}
                  onClick={() => {
                    form
                      .validateFields()
                      .then((values) => {
                        form.resetFields();
                        handleSubmitWithNext(values);
                      })
                      .catch((info) => {
                        console.log("Validate Failed:", info);
                      });
                  }}
                >
                  Submit & Next
                </Button>
                <Button style={{ marginLeft: "1rem" }} onClick={
                  () => {
                    deleteAnnotation.mutateAsync(id);
                    form.resetFields();
                  }
                }>
                  Reset
                </Button>
              </Form.Item>
            </Form>
          )}
        </Col>
      </Row>
    </div>
  );
};

const ValidationCards = ({ data, isLoading, onSelect, onPageChange }) => {
  return (
    <List
      pagination={{
        pageSize: 10,
        showSizeChanger: false,
        onChange: (page) => {
          // console.log(page);
          onPageChange(page);

        },
        total: data.total,
        current: data.page,
      }}
      dataSource={data ? data.results : []}
      loading={isLoading}
      renderItem={(item, idx) => (
        <List.Item key={idx}>
          <div>
            <div>
              <Tag color="blue">
                {item.pubmed_id} : {item.drug}
              </Tag>
              {item.validation_status === "pending" ? (
                <Tag color="orange">Pending</Tag>
              ) : (
                <Tag color="green">Completed</Tag>
              )}
              {item.validators && item.validators.length > 0 && (
                <Tag color="skyblue">
                  Labelled by: {item.validators?.join(",")}
                </Tag>
              )}
            </div>
            <div
              style={{
                marginTop: "0.5rem",
              }}
            >
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                {item.article?.title}
              </span>
            </div>
            <p>{item.description}</p>

            <div>
              <Button size="small" onClick={() => onSelect(item)}>
                Validate
              </Button>
            </div>
          </div>
        </List.Item>
      )}
    ></List>
  );
};

const ValidationView = () => {
  const match = useMatch("/validation/:id");

  const [state, setState] = useState({
    loading: true,
    page: 1,
    search: "",
  });

  const [filter, setFilter] = useState({
    status: "",
    validator: "",
    search: "",
  });

  const validationTasks = useQuery(
    ["validation_tasks", state.page, state.search, filter.status, filter.validator],
    () =>
      ValidationTask.getAll({
        page: state.page,
        search: state.search,
        validation_set_id: match?.params.id,
        status: filter.status,
        validator: filter.validator,
      }),
    {}
  );

  const [selectedArticle, setSelectedArticle] = useState(null);

  const getNext = () => {
    ValidationTask.getNext(selectedArticle.id, {validation_set_id: match.params.id}).then((res) => {
      setSelectedArticle(res);
    });
  };

  return (
    <>      <CustomHeader />

    <div
      style={{
        maxWidth: "1200px",
        margin: "0px auto",
        padding: "1rem",
      }}
    >
      <Typography.Title level={4}>Validation</Typography.Title>
      <Row>
        <Space>
          <Input.Search
            onSearch={(e) => {
              setState((prev) => ({ ...prev, search: e }));
            }}
          ></Input.Search>
          <Select
            defaultValue="pending"
            style={{ width: 120 }}
            onChange={(value) => {
              setFilter((prev) => ({ ...prev, status: value }));
            }}
            value={filter.status}
          >
            <Select.Option value="">All</Select.Option>
            <Select.Option value="pending">Pending</Select.Option>
            <Select.Option value="completed">Completed</Select.Option>
          </Select>

          <Input
            onPressEnter={(e) => {
              setFilter((prev) => ({ ...prev, validator: e.target.value }));
            }}
            placeholder="Validator"
            style={{ width: 200 }}
            suffix={<ArrowRightOutlined />}
          ></Input>
        </Space>
      </Row>

      {validationTasks.data ? (
        <ValidationCards
          data={validationTasks.data}
          isLoading={validationTasks.isLoading}
          onSelect={setSelectedArticle}
          onPageChange={(page) => {
            setState((prev) => ({ ...prev, page: page }));
          }}
        />
      ) : null}

      <Modal
        width="80vw"
        title={`Pubmed ID: ${selectedArticle?.pubmed_id} - Drug: ${selectedArticle?.drug}`}
        visible={selectedArticle}
        footer={null}
        onCancel={() => setSelectedArticle(null)}
      >
        {selectedArticle ? (
          <ResponseForm
            article={selectedArticle?.article}
            getNext={getNext}
            id={selectedArticle.id}
            key={selectedArticle.id}
          />
        ) : null}
      </Modal>
    </div>
    </>
  );
};

export default ValidationView;

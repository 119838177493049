import React from "react";
import { BookmarkService } from "../../services/dataService";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { message, Modal } from "antd";
import { Delete03Icon } from "./icons";

export default function BookMarkEdit({ visible, onCancel }) {
  const queryClient = useQueryClient();
  const bookmarks = useQuery(["bookmarks", "mine"], () =>
    BookmarkService.getAll({
      mine: true,
    })
  );
  const deleteMutation = useMutation((id) => BookmarkService.delete(id), {});

  const onDelete = (id) => {
    message.loading("Deleting bookmark...");
    deleteMutation.mutate(id, {
      onSuccess: () => {
        message.destroy();
        message.success("Bookmark deleted");
        queryClient.invalidateQueries(["bookmarks"]);
      },
    });
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title="Edit Bookmarks"
      footer={null}
    >
      <div className="flex flex-col gap-4 mt-4">
        {bookmarks.data?.results.map((bookmark) => (
          <div
            key={bookmark.id}
            className="flex items-center justify-between border-b border-gray-200 pb-2"
          >
            <span>{bookmark.name}</span>

            <button onClick={() => onDelete(bookmark.id)}>
              <Delete03Icon className="w-4 h-4 text-red-500" />
            </button>
          </div>
        ))}
      </div>
    </Modal>
  );
}

import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Radio,
  Select,
  Space,
  message,
  Divider,
} from "antd";
import React, { useState, useEffect } from "react";
import {
  MinusCircleOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { PubmedDataItems } from "../../services/dataService";
import ReactJson from 'react-json-view'

const { Option } = Select;

const DetailForm = (props) => {

  return (
    <div style={{ margin: "2rem 0rem" }}>
      {props.data &&
        Object.keys(props.data).map((key) => {
          console.log(key, props.data[key] == "object", props.data[key] );
          return (
            <Row key={key}>
              <Col span={8}>{key}:</Col>
              <Col span={16}>
                {typeof props.data[key] == "object"
                  ? props.data[key]? <ReactJson src={props.data[key]} /> : ""
                  : props.data[key]}
              </Col>
              <Divider style={{ margin: "8px 0px" }} type="horizontal" />
            </Row>
          );
        })}
      {/* <Row>
          <Col span={4}>Drugs Studied:</Col>
          <Col span={18}>{cleanedExtractions["cancer_drugs"]}</Col>
          <Divider style={{ margin: "8px 0px" }} type="horizontal" />
        </Row>
        <Row>
          <Col span={4}>Drugs Effect</Col>
          <Col span={18}>{cleanedExtractions["drug_effects"]}</Col>
          <Divider style={{ margin: "8px 0px" }} type="horizontal" />
        </Row>
        <Row>
          <Col span={4}>Patient Number</Col>
          <Col span={18}>{cleanedExtractions["patient_num"]}</Col>
          <Divider style={{ margin: "8px 0px" }} type="horizontal" />
        </Row>
        <Row>
          <Col span={4}>Study Type</Col>
          <Col span={18}>{cleanedExtractions["study_type"]}</Col>
          <Divider style={{ margin: "8px 0px" }} type="horizontal" />
        </Row>
        <Row>
          <Col span={4}>RCT Type</Col>
          <Col span={18}>{cleanedExtractions["study_rct"]}</Col>
          <Divider style={{ margin: "8px 0px" }} type="horizontal" />
        </Row>
        <Row>
          <Col span={4}>Therapy Type</Col>
          <Col span={18}>{cleanedExtractions["therapy_type"]}</Col>
          <Divider style={{ margin: "8px 0px" }} type="horizontal" />
        </Row> */}
      {}
      {/* <Form
        layout="vertical"
        form={form}
        name="register"
        onFinish={onFinish}
        // initialValues={props.existingLabels}
        style={{ maxWidth: 600 }}
        scrollToFirstError
      >
        <Form.Item
          name="relevance"
          label="Relevance"
          rules={[{ required: true, message: "Please add relevance label!" }]}
        >
          <Radio.Group optionType="button">
            <Radio value="include">Include</Radio>
            <Radio value="exclude">Exclude</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="relevanceComment"
          label="Relevance Explanation"
          rules={[{ required: true, message: "Please add explanation!" }]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          name="studyType"
          label="Study Type"
          rules={[{ required: true, message: "Please add Study Type!" }]}
        >
          <Radio.Group optionType="button">
            <Radio value="Preclinical">Preclinical</Radio>
            <Radio value="Clinical trial: non-RCT">
              Clinical trial: non-RCT
            </Radio>
            <Radio value="Clinical trial: RCT">Clinical trial: RCT</Radio>
            <Radio value="Observational study">Observational study</Radio>
            <Radio value="Meta-analysis">Meta-analysis</Radio>
            <Radio value="Review">Review</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="drugName"
          label="Drug Name"
          rules={[{ required: true, message: "Please add drug name!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="numPatients"
          label="Num Patients, if Clinical (else write 'N/A')"
          rules={[{ required: true, message: "Please add num patientsl!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="combinationStatus"
          label="Combination Status"
          rules={[{ required: true, message: "Combination Status!" }]}
        >
          <Radio.Group optionType="button">
            <Radio value="Alone">Alone</Radio>
            <Radio value="Combination">Combination</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="drugTheruapeticAssociation"
          label="Therapeutic Association"
          rules={[
            { required: true, message: "Please add Therapeutic Association!" },
          ]}
        >
          <Radio.Group optionType="button">
            <Radio value="No benefit">No benefit</Radio>
            <Radio value="Possible benefit">Possible benefit</Radio>
            <Radio value="Inconclusive">Inconclusive</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.List name="endpointResults">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  direction="vertical"
                  size="small"
                  //   style={{ display: "flex", marginBottom: 8 }}
                  //   align="baseline"
                >
                  <div>
                    Endpoint #{key + 1}{" "}
                    <DeleteOutlined
                      style={{ marginLeft: "1rem", color: "red" }}
                      onClick={() => remove(name)}
                    />
                  </div>
                  <Form.Item
                    {...restField}
                    name={[name, "name"]}
                    rules={[
                      { required: true, message: "Missing endpoint name" },
                    ]}
                  >
                    <Input placeholder="Endpoint Name" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "impact"]}
                    rules={[{ required: true, message: "Missing Impact" }]}
                  >
                    <Radio.Group optionType="button">
                      <Radio value="no_impact">No Impact</Radio>
                      <Radio value="positive_impact">Positive Impact</Radio>
                      <Radio value="negative_impact">Negative Impact</Radio>
                      <Radio value="unclear">Unclear</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "reference"]}
                    rules={[{ required: true, message: "Missing Impact" }]}
                  >
                    <Input placeholder="Reference" />
                  </Form.Item>
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add New Endpoint
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button
            loading={nextLabelItem.isLoading}
            style={{ marginLeft: "1rem" }}
            onClick={props.onNext}
          >
            Load Next
          </Button>
        </Form.Item>
      </Form> */}
    </div>
  );
};

export default DetailForm;

import {
  Button,
  Card,
  Checkbox,
  Divider,
  Input,
  message,
  Modal,
  Popover,
  Row,
  Space,
  Tabs,
  Tag,
  Typography,
} from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../authContext";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { BookmarkService } from "../../services/dataService";
import PDFDocView from "../pubmedData/DocView";
import { CheckCircleOutlined } from "@ant-design/icons";
import PubDetailViewV2 from "../pubmedData/detailViewV2";
import UploadPDF from "../pubmedData/importPDF";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const BookmarkOff02Icon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={24}
    height={24}
    color={"#000000"}
    fill={"none"}
    {...props}
    style={{
      height: "16px",
      width: "16px",
      color: "red",
    }}
  >
    <path
      d="M2 2L22 22"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 4.5L4.5 21.5L12 17.5L19.5 21.5V19.5M7 2.5H17.5C18.6046 2.5 19.5 3.39543 19.5 4.5V15.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const PublicationCard = ({
  item,
  onClick,
  onTabChange,
  selectedTab,
  showDetailView,
  isSelected,
  isSelectable,
  bulkSelect, // options for bulk select
  showBookmarkDelete,
  onBookmarkDelete,
}) => {
  const navigate = useNavigate();
  const [pdfUploadFlag, setPdfUploadFlag] = useState(false);
  const [detailViewFlag, setDetailViewFlag] = useState(false);
  const [newBookmarkName, setNewBookmarkName] = useState("");

  const [pdfFlag, setPdfFlag] = useState(false);
  const auth = useAuth();
  const queryClient = useQueryClient();
  const bookmarkList = useQuery(["bookmarks"], () => BookmarkService.getAll(), {
    staleTime: Infinity,
  });
  const bookmarkItem = useMutation(
    (bookmarkId) =>
      BookmarkService.addItem({
        bookmark_id: bookmarkId,
        // pubmed_id: selected?.pubmed_id,
        item_id: item?.id,
      }),
    {
      onSuccess: (data) => {
        message.success("Item added to bookmark");
        queryClient.invalidateQueries(["bookmarkItems"]);
      },
      onError: (error) => {
        message.error("Error adding item to bookmark");
      },
    }
  );
  const createNewBookmark = useMutation(
    (name) => BookmarkService.create({ owner_id: auth.user.id, name: name }),
    {
      onSuccess: (data) => {
        message.success("Bookmark created successfully");
        // bookmarkList.refetch();
        queryClient.invalidateQueries(["bookmarks"]);
      },
      onError: (error) => {
        message.error("Error creating bookmark");
      },
    }
  );

  console.log("ITEM", item);

  return item ? (
    <>
      {showDetailView && detailViewFlag && (
        <Modal
          width="80vw"
          visible={showDetailView && detailViewFlag}
          onCancel={() => setDetailViewFlag(false)}
          footer={null}
        >
          <PubDetailViewV2 itemId={item?.id} selected={item} />
        </Modal>
      )}
      {pdfUploadFlag && (
        <UploadPDF
          itemId={item?.pubmed_id}
          visible={pdfUploadFlag}
          onCancel={() => setPdfUploadFlag(false)}
          onConfirm={() => {
            setPdfUploadFlag(false);
          }}
        />
      )}
      {pdfFlag && (
        <PDFDocView
          docId={item?.pubmed_id}
          pmid={item?.pubmed_id}
          visible={pdfFlag}
          onClose={() => setPdfFlag(false)}
        />
      )}

      <Card
        onClick={() => {
          if (isSelectable) {
            onClick(item);
          }
        }}
        hoverable={isSelectable ? true : false}
        style={{
          margin: "1rem 0.75rem",
          background: "#fff8f6",
          border: isSelected ? "2px solid #ff4d4f" : null,
        }}
      >
        {bulkSelect && (
          <div style={{ position: "absolute", top: "0.5rem", right: "0.5rem" }}>
            <Checkbox
              type="checkbox"
              checked={bulkSelect.isSelected}
              onChange={() => {
                bulkSelect.toggle(item);
              }}
              style={{
                cursor: "pointer",

                color: "black",
              }}
            />
          </div>
        )}
        {showBookmarkDelete && (
          <div style={{ position: "absolute", top: "0.5rem", right: "0.5rem" }}>
            <Button
              type="link"
              onClick={(e) => {
                onBookmarkDelete(item.id);
                e.stopPropagation();
              }}
            >
              <BookmarkOff02Icon />
            </Button>
          </div>
        )}
        {item.pmid && (
          <Tag
            className="info-tag-top"
            // color="geekblue"
            onClick={() => {
              navigator.clipboard.writeText(`${item.pubmed_id}`);
              message.success("Copied PMID to clipboard");
            }}
          >
            <i className="fa-solid fa-copy" style={{ marginRight: "0.5rem" }}></i>

            {item.pubmed_id}
          </Tag>
        )}
        {item.doi && (
          <Tag
            className="info-tag-top"
            onClick={() => {
              // Go to the doi link
              try {
                if (item.doi.startsWith("http")) {
                  window.open(item.doi, "_target");
                } else {
                  window.open(`https://doi.org/${item.doi}`, "_target");
                }
              } catch (e) {
                console.error("Error opening doi link", e);
              }
            }}
          >
            {/* <i class="fa-solid fa-copy" style={{ marginRight: "0.5rem" }}></i> */}

            {item.doi}
          </Tag>
        )}
        <Tag className="info-tag-top">
          <i className="fa-solid fa-capsules" style={{ marginRight: "0.5rem" }}></i>
          {item.drug}
        </Tag>
        <Tag className="info-tag-top">
            <i className="fa-solid fa-dna" style={{ marginRight: "0.5rem" }}></i>

          {item.pipeline_outputs?.primary_tissue_final}
        </Tag>
        {item.is_validated && (
          <Tag className="info-tag-top">
            <CheckCircleOutlined /> Validated
          </Tag>
        )}
        <Tag
          className={
            item.final_relevance == "Include"
              ? "info-tag-top include"
              : "info-tag-top exclude"
          }
        >
          {item.final_relevance == "Include" ? "Include" : "Exclude"}
        </Tag>
        {item.is_supplementary && (
          <Tag className="info-tag-top"
            style={{
              background: "#fffcc3",
              color: "#6a6a6a",
              borderColor: "transparent",
            }}
          >Supplementary</Tag>
        )}
        <br />{" "}
        {/* <Typography.Title
          level={5}
          style={{ marginTop: "0.5rem", fontWeight: 500 }}
          className="pubmed-title"
        >
          {item["title"]}
        </Typography.Title> */}
        <h3 className={
          item.included_in_review ? "text-green-600 text-base my-2 font-medium" : "text-gray-600 text-base my-2 font-medium"
        }>
          {item["title"]}
          </h3>
        <Row>
          <Space>
            <Tag className="info-tag">
              <i class="fa-regular fa-calendar"></i>{" "}
              {item?.other_details?.publication_date ||
                item?.pipeline_outputs?.publisheddate}
            </Tag>
            <Tag className="info-tag">
              <i class="fa-solid fa-user-group"></i>
              {item.authors?.slice(0, 5).join(", ")}
            </Tag>
          </Space>
        </Row>
        <Row style={{ marginTop: "0.5rem" }}>
          <Space wrap>
            {item.gpt_data && (
              <>
                <div className="gpt-chip">
                  <span className="label">Rel:</span>
                  <span>{item.gpt_data.relevance}</span>
                </div>
                <div className="gpt-chip">
                  <span className="label">Study Type:</span>
                  <span>{item.gpt_data.study_type}</span>
                </div>
                <div className="gpt-chip">
                  <span className="label">Is RCT:</span>
                  <span>{item.gpt_data.study_type}</span>
                </div>
                <div className="gpt-chip">
                  <span className="label">Combination Therapy:</span>
                  <span>{item.gpt_data.combination}</span>
                </div>
                <div className="gpt-chip">
                  <span className="label">Patient #:</span>
                  <span>{item.gpt_data.patient_num}</span>
                </div>
              </>
            )}
          </Space>
        </Row>
        <Divider
          orientation="horizontal"
          style={{
            margin: "0.5rem 0rem",
          }}
        />
        {!isSelectable && (
          <Row>
            <Tabs
              size="small"
              activeKey={selectedTab}
              onChange={(key) => {
                onTabChange(key);
                console.log("Key", key);
              }}
              defaultValue={selectedTab}
            >
              <Tabs.TabPane tab="Summary" key="1">
                <Space direction="vertical">
                  <div className="summary-item">
                    <div>
                      <span className="label">Objective:</span>
                    </div>
                    <p>{item.summary?.objective}</p>
                  </div>
                  <div className="summary-item">
                    <div>
                      <span className="label">Methods:</span>
                    </div>
                    <p>{item.summary?.methods}</p>
                  </div>
                  <div className="summary-item">
                    <div>
                      <span className="label">Population:</span>
                    </div>
                    <p>{item.summary?.population_characteristics}</p>
                  </div>
                  <div className="summary-item">
                    <div>
                      <span className="label">Endpoints:</span>
                    </div>
                    <p>{item.summary?.endpoints_measured}</p>
                  </div>
                  <div className="summary-item">
                    <div>
                      <span className="label">Conclusion:</span>
                    </div>
                    <p>{item.summary?.conclusion}</p>
                  </div>
                </Space>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Abstract" key="2">
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  children={item.abstract}
                />
              </Tabs.TabPane>
            </Tabs>
          </Row>
        )}
        {!isSelectable ? (
          <Space wrap split={<Divider />}>
            <Button
              size="small"
              className="option-button"
              type="text"
              onClick={() => setDetailViewFlag(true)}
            >
              <i class="fa-solid fa-expand"></i>
              More Details
            </Button>
            <Button
              size="small"
              className="option-button"
              type="text"
              onClick={() =>
                window.open(
                  `https://pubmed.ncbi.nlm.nih.gov/${item?.pubmed_id}/`,
                  "_blank"
                )
              }
            >
              <i class="fa-solid fa-link"></i>
              Pubmed
            </Button>

            <Button
              size="small"
              className="option-button"
              type="text"
              onClick={() =>
                window.open(item.other_details?.full_text_url, "_blank")
              }
              disabled={!item?.other_details?.full_text_url}
            >
              <i class="fa-solid fa-file-lines"></i> Full text
            </Button>
            <Button
              size="small"
              className="option-button"
              type="text"
              disabled={!item?.has_pdf}
              onClick={() => {
                setPdfFlag(true);
              }}
            >
              <i class="fa-solid fa-file-pdf"></i>
              View PDF
            </Button>
            <Button
              size="small"
              className="option-button"
              type="text"
              onClick={() => setPdfUploadFlag(true)}
            >
              <i class="fa-solid fa-file-arrow-up"></i>
              Upload PDF
            </Button>
            {/* <Button size="small" className="option-button" type="text">
                <i class="fa-solid fa-arrow-up-right-from-square"></i>
                Open
              </Button> */}
            <Popover
              trigger="click"
              content={
                <div style={{ width: "250px" }}>
                  <Space
                    direction="vertical"
                    split={<Divider style={{ margin: "0px" }} />}
                  >
                    {bookmarkList.data?.results?.map((bookmark) => (
                      <Button
                        type="link"
                        onClick={() => {
                          bookmarkItem.mutate(bookmark.id);
                        }}
                      >
                        {bookmark.name}
                      </Button>
                    ))}
                    <Space.Compact
                      style={{ width: "100%", padding: "0 0.5rem" }}
                    >
                      <Input
                        size="small"
                        value={newBookmarkName}
                        onChange={(e) => {
                          setNewBookmarkName(e.target.value);
                        }}
                      />
                      <Button
                        onClick={() => {
                          createNewBookmark.mutate(newBookmarkName);
                        }}
                        size="small"
                        loading={createNewBookmark.isLoading}
                      >
                        Create New
                      </Button>
                    </Space.Compact>
                  </Space>
                </div>
              }
            >
              <Button className="option-button" type="link" size="small">
                <i class="fa-solid fa-book"></i>
                Bookmark
              </Button>
            </Popover>
          </Space>
        ) : (
          <Space wrap split={<Divider />}>
            {
              item.pubmed_id && (
            
            <Button
              size="small"
              className="option-button"
              type="text"
              onClick={() =>
                window.open(
                  `https://pubmed.ncbi.nlm.nih.gov/${item?.pubmed_id}/`,
                  "_blank"
                )
              }
            >
              <i class="fa-solid fa-link"></i>
              Pubmed
            </Button>
              )
            }

            {
              item?.doi && (
            <Button
                
              size="small"
              className="option-button"
              type="text"
              onClick={() => {
                // Go to the doi link
                try {
                  window.open(`${item.doi}`, "_blank");
                }
                catch (e) {
                  console.error("Error opening doi link", e);
                }
              }
            }
              >
              <i class="fa-solid fa-link"></i>
              DOI
              </Button>
              )
            }

            <Button
              size="small"
              className="option-button"
              type="text"
              onClick={() =>
                window.open(item.other_details?.full_text_url, "_blank")
              }
              disabled={!item?.other_details?.full_text_url}
            >
              <i class="fa-solid fa-file-lines"></i> Full text
            </Button>
            <Button
              size="small"
              className="option-button"
              type="text"
              disabled={!item?.has_pdf}
              onClick={() => setPdfFlag(true)}
            >
              <i class="fa-solid fa-file-pdf"></i>
              View PDF
            </Button>
            <Button
              size="small"
              className="option-button"
              type="text"
              onClick={() => {
                // Open in new tab
                // navigate(`/dataset/${item.pubmed_id}`);
                window.open(`/dataset/${item.id}`, "_blank");
              }}
            >
              <i class="fa-solid fa-file-export"></i>
              Open
            </Button>
          </Space>
        )}
      </Card>
    </>
  ) : null;
};

export default PublicationCard;

// Custom hook to handle filters with context provider
import React, { useContext, useState, createContext, useEffect } from "react";

const FiltersContext = createContext({});

const DEFAULT_FILTERS = {
  authors: [],
  drug: [],
  "extraction.primary_tissue_type": [],
  "extraction.relevance": null,
  "extraction.validated": null,
  included_in_review: null,
  "extraction.study_type": [],
  "extraction.ta": null,
  "extraction.combination": null,
};


export function FiltersProvider({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [search, setSearch] = useState("");


  useEffect(() => {
    // Read from quuery params if query params are present don't load from local storage
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.size > 0) {
      if (queryParams.get("search")) {
        setSearch(queryParams.get("search"));
      }
      queryParams.forEach((value, key) => {
        if (key !== "search") {
          if (value.split(",").length > 1) {
            setFilters((prev) => ({ ...prev, [key]: value.split(",") }));
          } else {
            setFilters((prev) => ({ ...prev, [key]: [value] }));
          }
        }
      });
    } else {
      // Try to load from local storage
      const search = localStorage.getItem("reboot-search");
      const filters = localStorage.getItem("reboot-filter");
      if (search) {
        setSearch(search);
        if (filters) {
          setFilters((prev) => ({ ...prev, ...JSON.parse(filters) }));
        }
      }
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (isLoading) return;
    localStorage.setItem("reboot-search", search);
    localStorage.setItem("reboot-filter", JSON.stringify(filters));

    // Add to query params
    const queryParams = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => {
      if (Array.isArray(value) && value.length > 0) {
        queryParams.set(key, value.join(","));
      } else if (!Array.isArray(value) && value) {
        queryParams.set(key, value);
      }
    });
    // Add to url
    window.history.pushState({}, "", `${window.location.pathname}?${queryParams.toString()}`);
  }, [search, filters]);

  const onClear = () => {
    setSearch("");
    setFilters(DEFAULT_FILTERS);
  };
  return (
    <FiltersContext.Provider
      value={{ filters, setFilters, search, setSearch, onClear }}
    >
      {children}
    </FiltersContext.Provider>
  );
}

export function useFilters() {
  const context = useContext(FiltersContext);
  if (!context) {
    throw new Error("useFilters must be used within a FiltersProvider");
  }
  return context;
}

import React from "react";

import {
  Row,
  Col,
  Button,
  Card,
  Tag,
  Typography,
  Layout,
  Menu,
  Anchor,
  Input,
  List,
} from "antd";
import { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";

import axios from "axios";
import remarkGfm from "remark-gfm";
import {
  createBrowserRouter,
  Link,
  RouterProvider,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";

import createPersistedState from "use-persisted-state";
import CustomHeader from "../../components/header";
import { LabelTask } from "../../services/dataService";
// import { Header } from "antd/es/layout/layout";
const labelAppState = createPersistedState("labelAppState");
const globalAppState = createPersistedState("globalAppState");

function LabelTaskPage() {
  const queryClient = useQueryClient();

  const [state, setState] = labelAppState({
    loading: true,
    page: 1,
    search: "",
  });
  const ref = useRef(null);
  useEffect(() => {}, []);

  useEffect(() => {
    ref.current?.scrollIntoView({
      block: "start",
      inline: "nearest",
      behavior: "smooth",
    });
  }, [state.selected]);

  const labelTasks = useQuery(
    ["label_tasks", state.page, state.search],
    () =>
      LabelTask.getAll({
        page: state.page,
        search: state.search,
      }),
    {}
  );

  return (
    <div>
      <CustomHeader />
      <div style={{ maxWidth: "1200px", margin: "0px auto" }}>
        {/* <header className="App-header"> */}
        {/* <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
        <Row>
          <Row style={{ width: "100%" }}>
            <Typography.Title level={3}>Labeltool</Typography.Title>
          </Row>
          <Row style={{ width: "100%" }}>
            <Col span={8}>
              <Input.Search
                onSearch={(e) => {
                  setState((prev) => ({ ...prev, search: e }));
                }}
              ></Input.Search>
            </Col>
          </Row>
          <Row style={{ width: "100%" }}>
            <List
              style={{ width: "100%" }}
              pagination={{
                onChange: (page, pageSize) => {
                  setState((prevState) => ({
                    ...prevState,
                    page: page,
                    size: pageSize,
                  }));
                },
                total: labelTasks.data ? labelTasks.data.total : 0,
                current: state.page,
                pageSize: state.size || 10,
              }}
              grid={{
                gutter: 2,
                xs: 1,
                sm: 2,
                md: 3,
                lg: 3,
                xl: 3,
                xxl: 4,
              }}
              dataSource={labelTasks.data ? labelTasks.data.results : []}
              // dataSource={labelItems.data}
              loading={labelTasks.isLoading}
              renderItem={(item, idx) => (
                <List.Item key={idx}>
                  <div key={item["External ID"]}>
                    <Link to={`/label/${item["id"]}`}>
                    <Card
                      style={{ margin: "0.5rem 0rem" }}
                      //   onClick={() =>
                      //     setState((prev) => ({
                      //       ...prev,
                      //       selected: item,
                      //       selectedIdx: idx,
                      //     }))
                      //   }
                    >
                      <Tag color="green">{item["task_type"]}</Tag>
                      <br />{" "}
                      <div style={{ marginTop: "0.25rem" }}>
                        {item["task_name"]}
                      </div>
                      <div style={{ marginTop: "0.25rem" }}>
                        <Typography.Text type="secondary">{item["task_description"]}</Typography.Text>
                      </div>
                    </Card>
                    </Link>
                  </div>
                </List.Item>
              )}
            />
          </Row>
        </Row>
      </div>
      {/* </header> */}
    </div>
  );
}

export default LabelTaskPage;

import { Button, Input, message, Modal } from "antd";
import React, { useState } from "react";
import { BookmarkService } from "../../services/dataService";
import { useAuth } from "../../authContext";
import { useMutation, useQuery, useQueryClient } from "react-query";

const BookMarkModal = ({ visible, setVisible, setSelectedBookmark }) => {
  const [newBookmarkName, setNewBookmarkName] = useState("");
  const auth = useAuth();
  const queryClient = useQueryClient();
  const createNewBookmark = useMutation(
    (name) => BookmarkService.create({ owner_id: auth.user.id, name: name }),
    {
      onSuccess: (data) => {
        message.success("Bookmark created successfully");
        // bookmarkList.refetch();
        queryClient.invalidateQueries(["bookmarks"]);
      },
      onError: (error) => {
        message.error("Error creating bookmark");
      },
    }
  );

  const bookmarkList = useQuery(["bookmarks"], () => BookmarkService.getAll(), {
    staleTime: Infinity,
  });

  return (
    <Modal
      title="Add to Bookmark"
      visible={visible}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <div className="flex flex-col gap-1">
        {bookmarkList.data?.results?.map((bookmark) => (
          <div
            key={bookmark.id}
            onClick={() => setSelectedBookmark(bookmark.id)}
            className="cursor-pointer text-blue-500 p-2 hover:bg-blue-50 rounded-md"
          >
            {bookmark.name}
          </div>
        ))}
        <div className="flex items-center gap-2">
          <Input
            value={newBookmarkName}
            onChange={(e) => setNewBookmarkName(e.target.value)}
          />
          <Button onClick={() => createNewBookmark.mutate(newBookmarkName)}>
            Create
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default BookMarkModal;

const parseSimpleQuery = (query, refQueries) => {
  // Parse a simple query like (title:"Cancer" OR title:"Tumor")
  // Assumuing there is no nested query

  // Replate all the paranthesis
  query = query.replace(/\(/g, "").replace(/\)/g, "");

  // Split by operator
  const tokens = query.split(/(AND|OR|NOT)/g);
  const operators = query.match(/AND|OR|NOT/g) || [];

  const esOverallQuery = {
    must: [],
    must_not: [],
    should: [],
  };

  let currentOperator = operators[0] || "AND";

  tokens.forEach((token) => {
    token = token.trim();
    if (token === "AND" || token === "OR" || token === "NOT") {
      currentOperator = token;
    } else {
      const match = token.match(/([^:]+):"(.*)"/);
      if (match) {
        const field = match[1];
        const value = match[2];

        const esField = mapField(field);

        const esQuery = {
          match_phrase: {
            [esField]: value,
          },
        };

        if (currentOperator === "OR") {
          esOverallQuery.should.push(esQuery);
        } else if (currentOperator === "NOT") {
          esOverallQuery.must_not.push(esQuery);
        } else {
          esOverallQuery.must.push(esQuery);
        }
      } else {
        // Check if it is a REF
        if (refQueries[token]) {
          if (currentOperator === "OR") {
            esOverallQuery.should.push({ bool: refQueries[token] });
          } else if (currentOperator === "NOT") {
            esOverallQuery.must_not.push({ bool: refQueries[token] });
          } else {
            esOverallQuery.must.push({ bool: refQueries[token] });
          }
        }else{
          // Check if it is a year
          if (currentOperator === "OR") {
            esOverallQuery.should.push({ match_phrase: { abstract: token } });
          } else if (currentOperator === "NOT") {
            esOverallQuery.must_not.push({ match_phrase: { abstract: token } });
          } else {
            esOverallQuery.must.push({ match_phrase: { abstract: token } });
          }
        }

        console.log("esOverallQuery", esOverallQuery);
      }
    }
  });

  return esOverallQuery;
};
function parsePubMedQuery(query) {
  try {
    // Recursively parse the query string (nested queries) and replace with "REF" and then finally create the query

    let refQueries = {};

    let count = 0;
    let newQuery = query;

    while (newQuery.match(/\([^()]*\)/)) {
      let subQuery = newQuery.match(/\([^()]*\)/)[0];
      let ref = `REF${count}`;
      let parsedQuery = parseSimpleQuery(subQuery, refQueries);
      refQueries[ref] = parsedQuery;
      count++;
      console.log("Count", count);

      // Replace 
      newQuery = newQuery.replace(subQuery, ref);
      // debugger
    }

    // Now backtrack from the remaining query  and replace the REF with the actual query

    let finalQuery = parseSimpleQuery(newQuery, refQueries);
    console.log("finalQuery", finalQuery);
    // Replace the REF with the actual query
    return finalQuery;
  } catch (e) {
    console.log("error", e);
  }
  return {};
}

function mapField(pubMedField) {
  const fieldMapping = {
    title: "title",
    abstract: "abstract",
    // year: "year",
    // Add more field mappings as needed
  };

  return fieldMapping[pubMedField] || pubMedField;
}

function buildElasticsearchQuery(parsedQuery) {
  const esQuery = {
    query: {
      bool: {},
    },
  };

  if (parsedQuery.must.length > 0) {
    esQuery.query.bool.must = parsedQuery.must;
  }

  if (parsedQuery.should.length > 0) {
    esQuery.query.bool.should = parsedQuery.should;
  }

  if (parsedQuery.must_not.length > 0) {
    esQuery.query.bool.must_not = parsedQuery.must_not;
  }

  return esQuery;
}

// Example usage
const pubMedQuery =
  '(author:"Smith J" AND (title:"Cancer" OR title:"Tumor") NOT journal:"Nature") OR year:"2020"';
const elasticsearchQuery = parsePubMedQuery(pubMedQuery);
console.log(JSON.stringify(elasticsearchQuery, null, 2));

export default parsePubMedQuery;

import React, { useState } from "react";
import { BookmarkService, PubmedDataItems } from "../../../services/dataService";
import { useMutation, useQuery } from "react-query";
import {
  Button,
  Checkbox,
  Col,
  Input,
  List,
  Modal,
  Row,
  Select,
  Tag,
  Typography,
} from "antd";
import { useNavigate } from "react-router-dom";
import { DRUG_CLASSES } from "../../../constants";

const PubmedSearch = () => {
  const [state, setState] = useState({
    page: 1,
    search: "",
    selectedArticles: [],
    importModalVisible: false,
  });

  const [drugName, setDrugName] = useState([]);
  const [noDrug, setNoDrug] = useState(false);
  const [isSupplemental, setIsSupplemental] = useState(false);
  const [drugClass, setDrugClass] = useState("");
  const [bookmarkId, setBookmarkId] = useState(null);
  const navigate = useNavigate();
  const bookmarks = useQuery(
    ["bookmarks"],
    () => BookmarkService.getAll({}),
    {}
  );
  const searchResults = useQuery(
    ["pubmedSearch", state.search, state.page],
    () =>
      PubmedDataItems.pubmedSearch({ search: state.search, page: state.page }),
    {
      enabled: state.search !== "",
    }
  );

  const importMutation = useMutation((data) => {
    return PubmedDataItems.importArticles(data);
  });

  const importArticles = async () => {
    const data = {
      drugs: noDrug ? "Unknown/Multiple Drug" : drugName.join("|"),
      bookmark: bookmarkId,
      pmids: state.selectedArticles.map((article) => article.pmid),
      is_supplemental: isSupplemental,
      drug_class: drugClass,
    };
    await importMutation.mutateAsync(data);

    setState({ ...state, importModalVisible: false });
  };

  return (
    <>
      <Modal
        title={`Import  ${state.selectedArticles.length} Articles`}
        visible={state.importModalVisible}
        onCancel={() => {
          setState({ ...state, importModalVisible: false });
        }}
        footer={null}
      >
        <Row align="middle" gutter={12}>
          <Col span={24}>
            <Typography.Text type="secondary">Drug Name:</Typography.Text>
          </Col>
          <Col span={24}>
            {/* <Input
              value={drugName}
              disabled={noDrug}
              onChange={(e) => {
                setDrugName(e.target.value);
              }}
            /> */}

            <Select
              style={{ width: "100%" }}
              allowClear
              mode="tags"
              placeholder="Enter Drug Names"
              onChange={(value) => {
                setDrugName(value);
              }}
              disabled={noDrug}
            />
          </Col>
        </Row>
        <Row
          align="middle"
          gutter={12}
          style={{
            margin: "0.5rem 0rem",
          }}
        >
          <Checkbox
            checked={noDrug}
            onChange={(e) => {
              if (e.target.checked) {
                setDrugName("Unknown/Multiple Drug");
                setNoDrug(true);
              } else {
                setDrugName("");
                setNoDrug(false);
              }
            }}
          >
            Unknown/Multiple Drugs
          </Checkbox>
        </Row>

        <Row
          align="middle"
          gutter={12}
          style={{
            margin: "0.5rem 0rem",
          }}
        >
          <Checkbox
            checked={isSupplemental}
            onChange={(e) => {
              if (e.target.checked) {
                setIsSupplemental(true);
              } else {
                setIsSupplemental(false);
              }
            }}
          >
            Supplemental Article
          </Checkbox>
        </Row>

        <Row align="middle" gutter={12}>
          <Col span={24}>
            <Typography.Text type="secondary">Drug Class:</Typography.Text>
          </Col>
          <Col span={24}>
            <Select
              style={{ width: "100%" }}
              allowClear
              placeholder="Enter Drug Class"
              onChange={(value) => {
                setDrugClass(value);
              }}
              options={DRUG_CLASSES.map((drug) => ({
                label: drug,
                value: drug,
              }))}
            />
          </Col>
        </Row>

        <Row
          align="middle"
          gutter={12}
          style={{
            margin: "0.75rem 0rem",
          }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            <Typography.Text type="secondary">Select Bookmark:</Typography.Text>
          </div>
          <div
            style={{
              width: "100%",
            }}
          >
            <Select
              style={{ width: "100%" }}
              allowClear
              placeholder="Select Bookmark"
              onChange={(value) => {
                setBookmarkId(value);
              }}
            >
              {bookmarks.data &&
                bookmarks.data?.results?.map((bookmark) => (
                  <Select.Option key={bookmark.id} value={bookmark.id}>
                    {bookmark.name}
                  </Select.Option>
                ))}
            </Select>
          </div>
        </Row>
        <Button
          type="primary"
          onClick={() => {
            importArticles();
          }}
          loading={importMutation.isLoading}
        >
          Import
        </Button>
      </Modal>

      <div>

        <div >
          <Row gutter={24}>
            <Col span={14}>
              <Input.Search
                onSearch={(e) => {
                  setState({ ...state, search: e, selectedArticles: [] });
                }}
              />
            </Col>
            <Col span={10}>
              {state.selectedArticles.length > 0 && (
                <Button
                  type="primary"
                  onClick={() => {
                    setState({ ...state, importModalVisible: true });
                  }}
                >
                  Import {state.selectedArticles.length} Articles
                </Button>
              )}
            </Col>
          </Row>

          {/* <Row style={{ margin: "2rem 2rem" }}>
            <Col span={14}>
                {searchResults.isLoading && <div>Loading...</div>}
                {searchResults.isSuccess && (
                <div>
                    {searchResults.data.results.map((item) => (
                    <div key={item.pmid}>
                        <h3>{item.title}</h3>
                        <p>{item.abstract}</p>
                    </div>
                    ))}
                </div>
                )}
            </Col>
        </Row> */}

          <List
            style={{
              marginTop: "2rem",
            }}
            pagination={{
              onChange: (page, pageSize) => {
                setState({ ...state, page: page });
              },
              total: searchResults.data ? searchResults.data.total : 0,
              current: state.page,
              pageSize: 10,
            }}
            dataSource={searchResults.data ? searchResults.data.results : []}
            loading={searchResults.isLoading}
            renderItem={(item) => (
              <List.Item>
                <div>
                  <Tag
                    color="green"
                    onClick={() => {
                      // window.location.href = `https://pubmed.ncbi.nlm.nih.gov/${item.pmid}`;
                      window.open(
                        `https://pubmed.ncbi.nlm.nih.gov/${item.pmid}`,
                        "_blank"
                      );
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    PMID: {item.pmid}
                  </Tag>
                  <Tag color="blue">{item.publication_date}</Tag>
                  <Row
                    align="middle"
                    style={{
                      margin: "0.5rem 0rem",
                    }}
                  >
                    <Checkbox
                      style={{
                        marginRight: "1rem",
                      }}
                      value={
                        state.selectedArticles.filter(
                          (article) => article.pmid === item.pmid
                        ).length > 0
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setState((prev) => ({
                            ...prev,
                            selectedArticles: [...prev.selectedArticles, item],
                          }));
                        } else {
                          setState((prev) => ({
                            ...prev,
                            selectedArticles: prev.selectedArticles.filter(
                              (article) => article.pmid !== item.pmid
                            ),
                          }));
                        }
                      }}
                    />

                    <Typography.Title
                      level={4}
                      style={{
                        margin: "0rem 0rem",
                        color:
                          state.selectedArticles.filter(
                            (article) => article.pmid === item.pmid
                          ).length > 0
                            ? "#0958d9"
                            : "black",
                      }}
                    >
                      {item.title}
                    </Typography.Title>
                  </Row>
                  <Typography.Text type="secondary">
                    Authors: {item.authors.join(", ")}
                  </Typography.Text>
                  <p>{item.abstract}</p>
                </div>
              </List.Item>
            )}
          />
        </div>
      </div>
    </>
  );
};

export default PubmedSearch;

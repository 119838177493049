import {
  Button,
  Divider,
  Input,
  message,
  Modal,
  Popover,
  Space,
  Tag,
} from "antd";
import React, { useState } from "react";
import PublicationCard from "./card";
import { BookmarkService } from "../../services/dataService";
import { useAuth } from "../../authContext";
import { useMutation, useQuery, useQueryClient } from "react-query";

const CreateSelectBookmark = ({ onSelectBookmark }) => {
  const [newBookmarkName, setNewBookmarkName] = useState("");
  const auth = useAuth();
  const queryClient = useQueryClient();
  const createNewBookmark = useMutation(
    (name) => BookmarkService.create({ owner_id: auth.user.id, name: name }),
    {
      onSuccess: (data) => {
        message.success("Bookmark created successfully");
        // bookmarkList.refetch();
        queryClient.invalidateQueries(["bookmarks"]);
      },
      onError: (error) => {
        message.error("Error creating bookmark");
      },
    }
  );

  const bookmarkList = useQuery(["bookmarks"], () => BookmarkService.getAll(), {
    staleTime: Infinity,
  });
  return (
    <div style={{ width: "250px" }}>
      <Space direction="vertical" split={<Divider style={{ margin: "0px" }} />}>
        {bookmarkList.data?.results?.map((bookmark) => (
          <Button
            type="link"
            onClick={() => {
              //   bookmarkItem.mutate(bookmark.id);
              onSelectBookmark(bookmark.id);
            }}
          >
            {bookmark.name}
          </Button>
        ))}
        <Space.Compact style={{ width: "100%", padding: "0 0.5rem" }}>
          <Input
            size="small"
            value={newBookmarkName}
            onChange={(e) => {
              setNewBookmarkName(e.target.value);
            }}
          />
          <Button
            onClick={() => {
              createNewBookmark.mutate(newBookmarkName);
            }}
            size="small"
            loading={createNewBookmark.isLoading}
          >
            Create New
          </Button>
        </Space.Compact>
      </Space>
    </div>
  );
};

const SelectedArticlesView = ({ selectedArticles, onRemove, removeAll }) => {
  const auth = useAuth();
  const queryClient = useQueryClient();
  // Modal to show selected items
  const [visible, setVisible] = useState(false);


  const bookmarkItems = useMutation(
    (bookmarkId) =>
      BookmarkService.addItems(
        selectedArticles.map((item) => ({
        bookmark_id: bookmarkId,
        // pubmed_id: selected?.pubmed_id,
        item_id: item?.id,
      }))),
    {
      onSuccess: (data) => {
        message.success(` ${selectedArticles.length} Items added to bookmark`);
        queryClient.invalidateQueries(["bookmarkItems"]);
        // Also remove the selected items
        removeAll();
      },
      onError: (error) => {
        message.error("Error adding item to bookmark");
      },
    }
  );

  return (
    <div>
      {/* <h1>Selected Items</h1>
            {selectedItems.map((item, index) => (
                <div key={index}>
                    <h2>{item.title}</h2>
                    <p>{item.abstract}</p>
                </div>
            ))} */}

      <Modal
        title={
          <div
            style={{
              display: "flex",
              gap: "1rem",
            }}
          >
            <h1>Selected Articles</h1>
            <Tag color="blue">{selectedArticles.length} Selected</Tag>
            <Popover
              content={
                <CreateSelectBookmark
                  onSelectBookmark={(id) => {
                    bookmarkItems.mutate(id);
                  }}
                />
              }
              title="Select Bookmark"
              trigger="click"
            >
              <Button type="primary" size="small">
                Add to Bookmark
              </Button>
            </Popover>
          </div>
        }
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={null}
        width={800}
      >
        <div
          style={{
            marginBottom: "1rem",
            maxHeight: "70vh",
            overflow: "scroll",
          }}
        >
          {selectedArticles.map((item, index) => (
            <div key={index}>
              <PublicationCard
                item={item}
                bulkSelect={{
                  isSelected: true,
                  toggle: () => onRemove(item),
                }}
              />
            </div>
          ))}
        </div>
      </Modal>
      <Tag
        color="blue"
        style={{
          cursor: "pointer",
        }}
        onClick={() => setVisible(true)}
      >
        {selectedArticles.length} Selected
      </Tag>
    </div>
  );
};

export default SelectedArticlesView;

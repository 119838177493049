import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Radio,
  Select,
  Space,
  message,
} from "antd";
import React, { useState, useEffect } from "react";
import {
  MinusCircleOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { LabelItem } from "../services/dataService";
import SelectEndpoint from "./selectEndpoint";
const { Option } = Select;

// const formItemLayout = {
//   labelCol: {
//     xs: { span: 24 },
//     sm: { span: 8 },
//   },
//   wrapperCol: {
//     xs: { span: 24 },
//     sm: { span: 16 },
//   },
// };

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const ResponseForm = (props) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  useEffect(() => {
    // Fetch the labelled and show results
    if (!props.existingLabels || props.existingLabels == {}) {

      form.resetFields();
      form.setFieldsValue({
        numPatients: "N/A",
        comparisonStudy: "No",
        // drugName: props.drugName,
      });
    } else {
      form.resetFields();
      form.setFieldsValue(props.existingLabels);
    }
  }, [props.existingLabels, props.itemId]);

  const labelItem = useQuery(
    ["labelItem", props.itemId],
    () => LabelItem.get(props.itemId, { acquire_lock: true }),
    {
      enabled: props.itemId != undefined,
    }
  );

  const nextLabelItem = useMutation(() =>
    LabelItem.get_next(labelItem.data.id, {
      acquire_lock: true,
      is_labelled: false,
      task_id: labelItem.data.task_id,
    }, {
      onSuccess: (data) => {
        debugger
        props.onNext(data);
      }
    })
  );

  const loadAndMoveToNext = async () => {
    nextLabelItem.mutateAsync().then((data) => {
      props.onNext(data);
    });

    //  props.onNext(nextLabelItem.data);
  };


  const saveLabel = useMutation((values) =>
    LabelItem.addLabel(labelItem.data.id, values),
    {
      onSuccess: () => {
        message.success("Labels saved!");
        queryClient.invalidateQueries("label_items");
      },
      onError: () => {
        message.error("Save Failed");
      }
    }
  );

  const onFinish = (values) => {
    saveLabel.mutate(values);
  };

  return (
    <div style={{ margin: "2rem 0rem" }}>
      <Form
        layout="vertical"
        form={form}
        name="register"
        onFinish={onFinish}
        // initialValues={props.existingLabels}
        initialValues={{'numPatients': 'N/A', "comparisonStudy": "No", 'drugName': props.drugName}}
        style={{ maxWidth: 600 }}
        scrollToFirstError
      >
        <Form.Item
          name="relevance"
          label="Relevance"
          rules={[{ required: true, message: "Please add relevance label!" }]}
        >
          <Radio.Group optionType="button">
            <Radio value="include">Include</Radio>
            <Radio value="exclude">Exclude</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="relevanceComment"
          label="Relevance Explanation"
          rules={[{ required: true, message: "Please add explanation!" }]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          name="studyType"
          label="Study Type"
          rules={[{ required: true, message: "Please add Study Type!" }]}
        >
          <Radio.Group optionType="button">
            <Radio value="Preclinical">Preclinical</Radio>
            <Radio value="Case Report">
              Case Report
            </Radio>
            <Radio value="Clinical trial: non-RCT">
              Clinical trial: non-RCT
            </Radio>
            <Radio value="Clinical trial: RCT">Clinical trial: RCT</Radio>
            <Radio value="Observational study">Observational study</Radio>
            <Radio value="Meta-analysis">Meta-analysis</Radio>
            <Radio value="Review">Review</Radio>
          </Radio.Group>
        </Form.Item>


        <Form.Item
          name="numPatients"
          label="Num Patients, if Clinical (else write 'N/A')"
          rules={[{ required: true, message: "Please add num patientsl!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="tissueType"
          label="Tissue Type"
          rules={[{ required: false, message: "Please add  queried tissue type!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="drugName"
          label="Queried Drug Name"
          rules={[{ required: true, message: "Please add  queried drug name!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="queriedDrugEvaluated"
          label="Was the queried drug evaluated?"
        >
          <Radio.Group optionType="button">
            <Radio value="Yes">Yes</Radio>
            <Radio value="No">No</Radio>
          </Radio.Group>
        </Form.Item>
        

        <Form.Item
          name="primaryDrugs"
          label="List all the primary drugs evaluated (excluding comparators)"
          rules={[{ required: false, message: "Please add all primary drugs" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="combinationStatus"
          label="Primary drug combination status"
          rules={[
            { required: false, message: "Please choose!" },
          ]}
        >
          <Radio.Group optionType="button">
            <Radio value="Alone">Alone</Radio>
            <Radio value="Combination">Combination</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="comparisonStudy"
          label="Is there a comparator?"
          rules={[
            { required: false, message: "Please choose!" },
          ]}
        >
          <Radio.Group optionType="button">
            <Radio value="Yes">Yes</Radio>
            <Radio value="No">No</Radio>
          </Radio.Group>
        </Form.Item>


        <Form.Item
          name="comparatorTherapeutics"
          label="If there is a comparator, list the therapeutics:"
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="drugTheruapeticAssociation"
          label="Therapeutic Association"
          rules={[
            { required: false, message: "Please add Therapeutic Association!" },
          ]}
        >
          <Radio.Group optionType="button">
            <Radio value="No benefit">No benefit</Radio>
            <Radio value="Possible benefit">Possible benefit</Radio>
            <Radio value="Inconclusive">Inconclusive</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.List name="endpointResults">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  direction="vertical"
                  size="small"
                  //   style={{ display: "flex", marginBottom: 8 }}
                  //   align="baseline"
                >
                  <div>
                    Endpoint #{key + 1}{" "}
                    <DeleteOutlined
                      style={{ marginLeft: "1rem", color: "red" }}
                      onClick={() => remove(name)}
                    />
                  </div>

                  {/* <Form.Item
                    {...restField}
                    name={[name, "name"]}
                    rules={[
                      { required: true, message: "Missing endpoint name" },
                    ]}
                  >
                    <Input placeholder="Endpoint Name" />
                  </Form.Item> */}
                  <Form.Item
                    {...restField}
                    name={[name, "name"]}
                    rules={[
                      // { required: true, message: "Missing endpoint name" },
                    ]}
                  >
                    <SelectEndpoint />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "type"]}
                    rules={[{ required: true, message: "Missing Impact" }]}
                  >
                    <Radio.Group optionType="button">
                      <Radio value="qualitative">Qualitative</Radio>
                      <Radio value="quantitative">Quantitative</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[name, "impact"]}
                    rules={[{ required: true, message: "Missing Impact" }]}
                  >
                    <Radio.Group optionType="button">
                      <Radio value="no_impact">No Impact</Radio>
                      <Radio value="positive_impact">Positive Impact</Radio>
                      <Radio value="negative_impact">Negative Impact</Radio>
                      <Radio value="unclear">Unclear</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "reference"]}
                    rules={[{ required: true, message: "Missing Impact" }]}
                  >
                    <Input placeholder="Reference" />
                  </Form.Item>
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add New Endpoint
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button
            loading={nextLabelItem.isLoading}
            style={{ marginLeft: "1rem" }}
            onClick={props.onNext}
          >Load Next</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ResponseForm;

import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";

const baseUrl = process.env.REACT_APP_API_URL;

const authContext = createContext();

const ProviderAuth = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

const useAuth = () => {
  return useContext(authContext);
};

const useProvideAuth = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isProfileComplete, setIsProfileComplete] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [authToken, setAuthToken] = useState(null);
  useEffect(() => {
    checkIfSignedIn();
  }, []);

  const checkIfSignedIn = async () => {
    let expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    let access = localStorage.getItem("access_token");

    if (new Date().getTime() < expiresAt && access !== null) {
      console.log("User is signed in")
      await loadUser(access);
      setAuthToken(access)
    } else {
      debugger
      console.log("User is NOT signed in")

      setLoading(false);
    }
  };

  const loadUser = async (access) => {
    await axios
      .get(`${baseUrl}/auth/users/me/`, {
        headers: { Authorization: `Bearer ${access}` },
      })
      .then((res) => {
        localStorage.setItem("user", JSON.stringify(res.data));
        setUser(res.data);
        setIsAuthenticated(true);
        setIsProfileComplete(res.data.profileComplete);
        setLoading(false);
      });
  };

  const signup = (
    first_name,
    last_name,
    username,
    password,
    password2,
    email,
    role
  ) => {
    return axios
      .post(`${baseUrl}/register/`, {
        first_name: first_name,
        last_name: last_name,
        username: username,
        password: password,
        password2: password2,
        email: email,
        role: role,
      })
      .then((data) => login(username, password));
  };

  const login = (username, password) => {
    // Send form data to backend
    const form = new FormData();
    form.append("username", username);
    form.append("password", password);

    return axios
      .post(`${baseUrl}/auth/token/`, form)
      .then(({ data }) => handleAuthentication(data))
      .then((authResult) => {
        return loadUser(authResult.access_token).then(() => {});
      });
  };

  const handleAuthentication = (authResult) => {
    if (authResult && authResult.access_token) {
      setSession(authResult);
      // history.push("/");
    }
    return authResult;
  };

  const setSession = (authResult) => {
    let expiresAt = JSON.stringify(3600 * 24 * 7* 1000 + new Date().getTime());
    localStorage.setItem("access_token", authResult.access_token);
    localStorage.setItem("expires_at", expiresAt);
    setAuthToken(authResult.access_token);
  };
  const logout = () => {
    // Clear access token and ID token from local storage
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("access_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("user");
    setUser(null);
    setIsAuthenticated(false);
    setIsProfileComplete(false);
  };

  const sendPasswordResetEmail = (email) => {};

  const confirmPasswordReset = (code, password) => {};

  const sendEmailVerification = () => {};

  const confirmEmailVerification = (code) => {};

  const updateProfile = (data) => {};

  // return
  return {
    user,
    loading,
    error,
    isAuthenticated,
    isProfileComplete,
    isEmailVerified,
    authToken,
    signup,
    login,
    logout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    sendEmailVerification,
    confirmEmailVerification,
    updateProfile,
  };
};

export { ProviderAuth, useAuth };

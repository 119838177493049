import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Dashboard from "./pages/dashboard";
import Auth from "../services/auth";
import ScoringView from "./pages/scoring";
import PubmedSearch from "./pages/pubmedSearch";
import Validation from "./pages/validation";
import PipelineRun from "./pages/pipelineRun";

const Header = () => {
  const auth = new Auth();
  const navigate = useNavigate();
  const pages = [
    { label: "Dashboard", path: "dashboard" },
    { label: "Scoring", path: "scoring" },
    { label: "Pubmed Search", path: "pubmed" },
    { label: "Validation", path: "validation" },
    { label: "Pipeline Run", path: "pipeline-run" },
  ];
  let location = useLocation();
  let pathname = location.pathname.split("/")[2];

  let activePage = pages.find((page) => page.path === pathname)?.path;
  return (
    <div className="flex justify-between items-center ">
      <img
        src="/reboot-logo.png"
        style={{ height: "50px", marginTop: "0.5rem" }}
      />
      <div className="flex gap-12 items-center">
        {pages.map((page) => (
          <Link
            to={page.path}
            className={`text-sm ${
              activePage === page.path
                ? "text-blue-500 font-semibold"
                : "text-neutral-500"
            }`}
          >
            {page.label}
          </Link>
        ))}
        <button
          className="text-sm text-neutral-500 transition hover:text-blue-500"
          onClick={() => {
            auth.logout();
            navigate("/");
            window.location.reload();
          }}
        >
          Logout
        </button>
      </div>
    </div>
  );
};

const Root = () => {
  return (
    <div className="flex flex-col h-screen max-w-screen-2xl mx-auto px-6">
      <div className="">
        <Header />
      </div>
      <div className="flex-1 overflow-y-auto pt-4">
        <Outlet />
      </div>
    </div>
  );
};

let v2Router = {
  path: "v2",
  element: <Root />,
  children: [
    {
      path: "dashboard",
      element: <Dashboard />,
    },
    {
      path: "scoring",
      element: <ScoringView />,
    },
    {
      path: "pubmed",
      element: <PubmedSearch />,
    },
    {
      path: "validation",
      element: <Validation />,
    },
    {
      path: "pipeline-run",
      element: <PipelineRun />,
    },
  ],
};

export default v2Router;

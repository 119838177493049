import React from "react";
import { useQuery } from "react-query";
import { useMatch, useNavigate } from "react-router-dom";
import { PubmedDataItems } from "../../services/dataService";
import PubDetailViewV2 from "../pubmedData/detailViewV2";
import CustomHeader from "../../components/header";

const PubmedDataDetail = () => {
  const match = useMatch("/dataset/:id");

  const pmid = match.params.id;
  // const pubmedItem = useQuery(["pubmedItem", pmid], async () => {
  //     return PubmedDataItems.get(pmid);
  // }
  // );

  const pubmedItem = useQuery(
    ["pubmedItem", pmid],
    () => PubmedDataItems.get(pmid),
    {
      onSuccess: (data) => {
        // setFeedback(data?.feedback || {});
      },
      staleTime: Infinity,
      enabled: pmid ? true : false,
    }
  );

  if (pubmedItem.isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      <div>
        <CustomHeader />
        <div style={{ margin: "0px 2rem" }}>
          {
            pubmedItem.data && !pubmedItem.isLoading && <PubDetailViewV2 selected={pubmedItem.data} />
          }
          {/* <PubDetailViewV2 selected={pubmedItem.data} /> */}
        </div>
      </div>
    </div>
  );
};

export default PubmedDataDetail;

const SparklesIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"#9013fe"} fill={"none"} {...props}>
      <path opacity="0.4" d="M15 1.25C15.3507 1.25 15.6546 1.49305 15.7317 1.83518L16.2704 4.22676C16.6637 5.97278 18.0272 7.33629 19.7732 7.7296L22.1648 8.26833C22.507 8.3454 22.75 8.64929 22.75 9C22.75 9.35071 22.507 9.6546 22.1648 9.73167L19.7732 10.2704C18.0272 10.6637 16.6637 12.0272 16.2704 13.7732L15.7317 16.1648C15.6546 16.507 15.3507 16.75 15 16.75C14.6493 16.75 14.3454 16.507 14.2683 16.1648L13.7296 13.7732C13.3363 12.0272 11.9728 10.6637 10.2268 10.2704L7.83518 9.73167C7.49305 9.6546 7.25 9.35071 7.25 9C7.25 8.64929 7.49305 8.3454 7.83518 8.26833L10.2268 7.7296C11.9728 7.33629 13.3363 5.97278 13.7296 4.22676L14.2683 1.83518C14.3454 1.49305 14.6493 1.25 15 1.25Z" fill="currentColor" />
      <path d="M7 11.25C7.35071 11.25 7.6546 11.493 7.73167 11.8352L8.11647 13.5435C8.37923 14.7099 9.29012 15.6208 10.4565 15.8835L12.1648 16.2683C12.507 16.3454 12.75 16.6493 12.75 17C12.75 17.3507 12.507 17.6546 12.1648 17.7317L10.4565 18.1165C9.29012 18.3792 8.37923 19.2901 8.11647 20.4565L7.73167 22.1648C7.6546 22.507 7.35071 22.75 7 22.75C6.64929 22.75 6.3454 22.507 6.26833 22.1648L5.88353 20.4565C5.62077 19.2901 4.70988 18.3792 3.54345 18.1165L1.83518 17.7317C1.49305 17.6546 1.25 17.3507 1.25 17C1.25 16.6493 1.49305 16.3454 1.83518 16.2683L3.54345 15.8835C4.70988 15.6208 5.62077 14.7099 5.88353 13.5435L6.26833 11.8352C6.3454 11.493 6.64929 11.25 7 11.25Z" fill="currentColor" />
    </svg>
  );

  const FilterIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"#4a4a4a"} fill={"none"} {...props}>
      <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M6.18213 2.25C6.19946 2.25 6.21686 2.25 6.23431 2.25L17.8178 2.25C18.5884 2.24997 19.2426 2.24994 19.7587 2.32026C20.3049 2.3947 20.8273 2.56273 21.2272 3.00266C21.6306 3.44644 21.741 3.98125 21.7494 4.52794C21.7573 5.0385 21.6757 5.67272 21.5805 6.41218L21.5735 6.46603C21.5399 6.72771 21.4893 6.9808 21.384 7.23419C21.2771 7.49146 21.1298 7.71058 20.9464 7.92833C19.9667 9.09153 18.1454 11.1937 15.5814 13.108C15.54 13.139 15.4876 13.2124 15.4776 13.3225C15.2294 16.0654 15.0167 17.4903 14.8513 18.3729C14.68 19.2866 13.9733 19.9238 13.3719 20.3592C13.0559 20.588 12.7217 20.7934 12.4259 20.9732C12.3991 20.9895 12.3726 21.0055 12.3466 21.0213C12.0744 21.1866 11.8463 21.3251 11.6591 21.4574C11.1464 21.8198 10.5353 21.8337 10.0428 21.5646C9.56871 21.3055 9.25434 20.8158 9.18796 20.2555C9.0506 19.0959 8.79286 16.7485 8.51176 13.3161C8.50273 13.206 8.45034 13.1318 8.40839 13.1005C5.84956 11.1885 4.03182 9.09 3.05351 7.92829C2.87013 7.71054 2.72289 7.49143 2.61595 7.23416C2.51062 6.98078 2.46012 6.7277 2.42644 6.46603C2.42412 6.44801 2.42181 6.43006 2.41951 6.41217C2.32429 5.67272 2.24262 5.0385 2.25053 4.52794C2.259 3.98125 2.36942 3.44644 2.7728 3.00266C3.17269 2.56273 3.69502 2.3947 4.2413 2.32026C4.75741 2.24994 5.41157 2.24997 6.18213 2.25Z" fill="currentColor" />
      <path d="M6.18213 2.25C5.41157 2.24997 4.75741 2.24994 4.2413 2.3203C3.69502 2.39478 3.17269 2.56291 2.7728 3.0031C2.36942 3.44714 2.259 3.98226 2.25053 4.52927C2.24262 5.04013 2.32429 5.67472 2.41951 6.4146C2.45567 6.69563 2.50669 6.97407 2.61595 7.23708C2.72289 7.49449 2.87013 7.71373 3.05351 7.93162C4.79297 9.99837 6.5433 11.9258 8.67601 13.4721C9.67714 14.1986 10.7027 15.0001 12 15C13.2973 14.9999 14.3228 14.1983 15.3238 13.4717C17.4561 11.9253 19.2073 9.99775 20.9464 7.93165C21.1298 7.71377 21.2771 7.49453 21.384 7.23711C21.4893 6.98357 21.5399 6.73033 21.5735 6.4685C21.656 5.82721 21.7595 5.17764 21.7494 4.52927C21.741 3.98226 21.6306 3.44714 21.2272 3.0031C20.8273 2.56291 20.3049 2.39478 19.7587 2.3203C19.2426 2.24994 18.5884 2.24997 17.8178 2.25H6.18213Z" fill="currentColor" />
  </svg>
);

export { SparklesIcon, FilterIcon };
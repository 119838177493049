import React from "react";
import CustomHeader from "../../components/header";
import {
  List,
  Tag,
  Typography,
  Button,
  Form,
  Input,
  Radio,
  Space,
  Modal,
  Row,
  Col,
  Select,
  message,
  Card,
} from "antd";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { ValidationTask } from "../../services/dataService";
import { Link } from "react-router-dom";

const ValidationSets = () => {
  const valTask = useQuery(["validation_sets"], () =>
    ValidationTask.getSets().then((res) => res)
  );

  const exportDataMutation = useMutation((data) =>
    ValidationTask.exportData(data).then((res) => res)
  );

  return (
    <>      <CustomHeader />
   
    <div
      style={{
        maxWidth: "1200px",
        margin: "0px auto",
        padding: "1rem",
      }}
    >
      <Row align="middle" justify="space-between">
        <Typography.Title
          level={4}
          style={{
            margin: "1rem 0",
          }}
        >
          Validation Sets
        </Typography.Title>
        <Button
          onClick={() => {
            exportDataMutation.mutate();
          }}

          loading={exportDataMutation.isLoading}
        >
          Export
        </Button>
      </Row>

      <Row gutter={[24, 24]}>
        {valTask.data?.map((item) => (
          <Col span={8} key={item.id}>
            <Link to={`/validation/${item.id}`}>
              <Card title={item.name}></Card>
            </Link>
          </Col>
        ))}
      </Row>
    </div>
    </>
  );
};
export default ValidationSets;

import React, {
  Component,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { Row } from "antd";

import PDFViewContainer from "./adobe/pdfRender";

const PDFDocumentView = ({
  id,
  title,
  url,
  blob,
  dataLoader,
  annotations, 
  goTo,
  name,
  email,
  onSave
}) => {
  console.log("annotations", annotations)
  return (
    <>
      <div style={{ width: "100%", height:"100%" }}>
        {/* <Row> */}
          <PDFViewContainer
            key={id}
            fileId={id}
            fileName={title}
            // annotations={
            //   policyDetails.details?.policy_parsed_data?.highlights || []
            // }
            annotations={annotations|| []}
            dataLoader={dataLoader}
            url ={url}
            blob={blob}
            goTo={goTo}
            name={name}
            email={email}
            onSave={onSave} 
          />
        {/* </Row> */}
      </div>
    </>
  );
};

export default PDFDocumentView;

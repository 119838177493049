import React from "react";

import {
  Row,
  Col,
  Button,
  Card,
  Tag,
  Typography,
  Layout,
  Menu,
  Anchor,
  Input,
  List,
  Radio,
  Select,
  Tabs,
  Modal,
} from "antd";
import { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { PubmedConfig } from "../../services/dataService";
import { useMutation, useQuery } from "react-query";
import ReactJson from "react-json-view";

const NestedField = ({ field, value, onChange }) => {
  let properties = value.properties;

  const onChangeNested = (key, modValue) => {
    onChange({
      ...value,
      properties: { ...properties, [key]: { ...modValue } },
    });
  };

  return (
    <>
      {properties ? (
        <>
          <Row style={{ margin: "0.5rem" }} align="bottom">
            <Col span={8}>
              <Typography.Title level={5}>{field}</Typography.Title>
            </Col>
            <Col span={16}>
              <Input
                value={value.label}
                onChange={(e) =>
                  onChange({
                    ...value,
                    label: e.target.value,
                  })
                }
              ></Input>
            </Col>
          </Row>
          <div style={{ marginLeft: "1rem" }}>
            {Object.keys(properties).map((key) => {
              return (
                <NestedField
                  key={key}
                  field={key}
                  value={properties[key]}
                  onChange={(value) => onChangeNested(key, value)}
                ></NestedField>
              );
            })}
          </div>
        </>
      ) : null}
      {!properties ? (
        <Row style={{ margin: "0.5rem" }}>
          <Col span={8}>
            <Typography.Text>{field}</Typography.Text>
          </Col>
          <Col span={16}>
            <Input
              value={value.label}
              onChange={(e) =>
                onChange({
                  ...value,
                  label: e.target.value,
                })
              }
            ></Input>
          </Col>
        </Row>
      ) : null}
    </>
  );
};

const ConfigEditView = ({ open, onClose }) => {
  const [state, setState] = useState({});
  const config = useQuery(["config", open], () => PubmedConfig.getConfig(), {
    onSuccess: (data) => {
      setState({
        ...state,
        mappings:
          data?.["test-pubmed-data"]?.mappings ||
          data?.["pubmed-data"]?.mappings,
      });
    },
    staleTime: Infinity,
  });

  const updateConfig = useMutation(() => {
    let finalMapping = config.data;
    if (finalMapping["test-pubmed-data"]) {
      finalMapping["test-pubmed-data"].mappings = state.mappings;
    }
    if (finalMapping["pubmed-data"]) {
      finalMapping["pubmed-data"].mappings = state.mappings;
    }

    PubmedConfig.updateConfig(finalMapping);
  });

  console.log("State", state);
  return (
    <Modal
      title="Edit Config"
      open={open}
      onClose={onClose}
      onCancel={onClose}
      width={800}
      footer={null}
    >
      {/* <ReactJson src={config.data || {}}></ReactJson> */}
      {state.mappings ? (
        <NestedField
          field={"Mapping"}
          value={state.mappings}
          onChange={(value) => {
            setState((prev) => ({
              ...prev,
              mappings: { ...value },
            }));
          }}
        ></NestedField>
      ) : null}

      <Button onClick={updateConfig.mutate} loading={updateConfig.isLoading}>
        Save
      </Button>
    </Modal>
  );
};

export default ConfigEditView;

import axios from "axios";
// import history from "../history";
const baseUrl = process.env.REACT_APP_API_URL;

//request interceptor to add the auth token header to requests
axios.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("access_token");
    if (
      accessToken &&
      !config.url.includes("login") &&
      !config.url.includes("register")
    ) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//response interceptor to refresh token on receiving token expired error
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    // let refreshToken = localStorage.getItem("refresh_token");
    // if (
    //   refreshToken &&
    //   error.response.status === 401 &&
    //   !originalRequest._retry
    // ) {
    //   originalRequest._retry = true;
    //   return axios
    //     .post(`${baseUrl}/login/refresh/`, { refresh: refreshToken })
    //     .then((res) => {
    //       if (res.status === 200) {
    //         localStorage.setItem("access_token", res.data.access);
    //         let expiresAt = JSON.stringify(86000 * 1000 + new Date().getTime());
    //         localStorage.setItem("expires_at", expiresAt);
        
    //         console.log("Access token refreshed!");
    //         return axios(originalRequest);
    //       }
    //     });

    // Regex match to see if token is called
    if (error.response.status === 400 && originalRequest.url.match(/token/)) {
      // window.location.href = "/login/";
      return Promise.reject(error);
    }else if (error.response.status === 401){
      localStorage.removeItem("access_token");
      localStorage.removeItem("expires_at");
      localStorage.removeItem("user");
      window.history.pushState(null, null, "/login");
      window.location.reload();

    }
    return Promise.reject(error);
  }
);

//functions to make api calls
const api = {
  signup: (body) => {
    return axios.post(`${baseUrl}/auth/signup/`, body);
  },
  login: (body) => {
    return axios.post(`${baseUrl}/auth/login/`, body);
  },
  refreshToken: (body) => {
    return axios.post(`${baseUrl}/auth/refresh/`, body);
  },
  logout: (body) => {
    return axios.delete(`${baseUrl}/auth/logout/`, body);
  },
  getProtected: () => {
    return axios.get(`${baseUrl}/user/`);
  },
};

export { axios, api };

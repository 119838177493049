import React from "react";
import { useMutation, useQuery } from "react-query";
import { LLMService } from "../../services/dataService";
import { Button, Modal } from "antd";
import ReactMarkdown from "react-markdown";
import CustomHeader from "../../components/header";

const AllSummary = () => {
  const [visible, setVisible] = React.useState(false);
  const [selected, setSelected] = React.useState(null);
  const [topic, setTopic] = React.useState("");
  const summaries = useQuery("allSummary", async () =>
    LLMService.getAllSummary()
  );

  const handleCreateSummary = useMutation(
    (data) => {
      LLMService.createGPTSummary(data);
    },
    {
      onSuccess: () => {
        setVisible(false);
        summaries.refetch();
      },
    }
  );
  return (
    <div>
      <CustomHeader />

      <Modal
        title="New Topic"
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <textarea
          placeholder="Topic"
          onChange={(e) => setTopic(e.target.value)}
          className="border border-gray-300 p-2 w-full rounded-lg h-24"
        />
        <Button
          onClick={() => {
            handleCreateSummary.mutate({ topic: topic });
          }}
          className="mt-2"
        >
          Create
        </Button>
      </Modal>

      <Modal

        title="Summary"
        visible={selected}
        footer={null}
        onCancel={() => setSelected(null)}
        width="900px"
      >
        <div className="" style={{
            width: "800px"
        }}>
        <ReactMarkdown
          components={{
            a: ({ children, href }) => <a href={href} className="text-blue-500">{children}</a>,
            p: ({ children }) => <p className="text-sm my-2">{children}</p>,
            ol: ({ children }) => (
              <ol className="text-sm my-2 list-decimal pl-6">{children}</ol>
            ),
            ul: ({ children }) => (
              <ul className="text-sm my-2 list-disc pl-6">{children}</ul>
            ),
            table: ({ children }) => (
              <table className="table-auto">{children}</table>
            ),
            td: ({ children }) => (
              <td className="border-b px-4 py-1">{children}</td>
            ),

            h2: ({ children }) => (
              <h2 className="text-2xl my-8 font-semibold">{children}</h2>
            ),
            h3: ({ children }) => (
              <h3 className="text-xl my-6 font-semibold">{children}</h3>
            ),
            h4: ({ children }) => (
              <h4 className="text-lg my-4 font-medium">{children}</h4>
            ),
          }}
        >
          {selected?.output}
        </ReactMarkdown>
        </div>
      </Modal>
      <div className="max-w-screen-2xl mx-auto">
        <div className="flex justify-between mt-4">
          <h1 className="text-xl font-medium">GPT Summary</h1>
          <Button onClick={() => setVisible(true)}>New Topic</Button>
        </div>

        <div>
          {summaries.data?.map((summary) => (
            <div
              key={summary.id}
              onClick={() => setSelected(summary)}
              className="border border-gray-300 p-4 rounded-lg mt-4 cursor-pointer"
            >
              <span
                className={`${
                  summary.status === "completed"
                    ? "bg-green-600"
                    : "bg-yellow-600"
                } px-3 py-0.5 rounded text-white text-sm`}
              >
                {summary.status === "completed" ? "Completed" : "Pending"}
              </span>
              <h2>{summary.topic}</h2>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AllSummary;
